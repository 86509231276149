import * as types from "./mutation_types.js";
import { get } from "lodash";

export default {
  [types.SET_INITIAL_DATA](state, data) {
    Object.assign(state, data);
  },
  [types.RESET_CREDENTIAL_STATE](state) {
    state.errorsUpdatingIntegrationCredentials = [];
    state.isUpdatingIntegrationCredentials = false;
  },
  [types.REQUEST_CREATE_DEPLOY](state) {
    state.isCreatingDeploy = true;
  },
  [types.RECEIVE_CREATE_DEPLOY_SUCCESS](state, deploy) {
    state.isCreatingDeploy = false;
    state.deploy = deploy;
    state.errorsCreatingDeploy = [];
  },
  [types.RECEIVE_CREATE_DEPLOY_ERROR](state, errors) {
    state.isCreatingDeploy = false;
    state.deploy = null;
    state.errorsCreatingDeploy = errors;
  },
  [types.REQUEST_CREATE_CHECK](state) {
    state.isCreatingCheck = true;
  },
  [types.RECEIVE_CREATE_CHECK_SUCCESS](state, check) {
    state.isCreatingCheck = false;
    state.check = check;
    state.errorsCreatingCheck = [];
  },
  [types.RECEIVE_CREATE_CHECK_ERROR](state, errors) {
    state.isCreatingCheck = false;
    state.check = null;
    state.errorsCreatingCheck = errors;
  },
  [types.REQUEST_RENAME_INTEGRATION](state) {
    state.isUpdatingIntegration = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_RENAME_INTEGRATION_SUCCESS](state, integration) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_RENAME_INTEGRATION_ERROR](state, errors) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingIntegration = errors;
  },
  [types.REQUEST_SYNC_REPOS_INTEGRATION](state) {
    state.isSyncReposIntegration = true;
    state.errorsSyncReposIntegration = [];
  },
  [types.RECEIVE_SYNC_REPOS_INTEGRATION_SUCCESS](state) {
    state.isSyncReposIntegration = false;
    state.recentlySyncedRepos = true;
    state.errorsSyncReposIntegration = [];
  },
  [types.RECEIVE_SYNC_REPOS_INTEGRATION_ERROR](state, errors) {
    state.isSyncReposIntegration = false;
    state.errorsSyncReposIntegration = errors;
  },
  [types.REQUEST_SYNC_TEAMS_INTEGRATION](state) {
    state.isSyncTeamsIntegration = true;
    state.errorsSyncTeamsIntegration = [];
  },
  [types.RECEIVE_SYNC_TEAMS_INTEGRATION_SUCCESS](state) {
    state.isSyncTeamsIntegration = false;
    state.recentlySyncedTeams = true;
    state.errorsSyncTeamsIntegration = [];
  },
  [types.RECEIVE_SYNC_TEAMS_INTEGRATION_ERROR](state, errors) {
    state.isSyncTeamsIntegration = false;
    state.errorsSyncTeamsIntegration = errors;
  },
  [types.REQUEST_DEAUTH_INTEGRATION](state) {
    state.isDeauthIntegration = true;
    state.errorsDeauthIntegration = [];
  },
  [types.RECEIVE_DEAUTH_INTEGRATION_SUCCESS](state) {
    state.isDeauthIntegration = false;
    state.errorsDeauthIntegration = [];
    state.successDeauthIntegration = true;
  },
  [types.RECEIVE_DEAUTH_INTEGRATION_ERROR](state, errors) {
    state.isDeauthIntegration = false;
    state.errorsDeauthIntegration = errors;
  },
  [types.REQUEST_SYNC_ALERT_SOURCES_INTEGRATION](state) {
    state.isSyncingAlertSources = true;
    state.recentlySyncedAlertSources = true;
    state.errorsSyncingAlertSources = [];
  },
  [types.RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_SUCCESS](state, integration) {
    state.integration = { ...state.integration, ...integration };
    state.isSyncingAlertSources = false;
    state.recentlySyncedAlertSources = false;
    state.errorsSyncingAlertSources = [];
  },
  [types.RECEIVE_SYNC_ALERT_SOURCES_INTEGRATION_ERROR](state, errors) {
    state.isSyncingAlertSources = false;
    state.errorsSyncingAlertSources = errors;
  },
  [types.REQUEST_SET_GROUP](state) {
    state.isUpdatingIntegration = true;
    state.errorsSettingGroup = [];
  },
  [types.RECEIVE_SET_GROUP_SUCCESS](state, integration) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsSettingGroup = [];
  },
  [types.RECEIVE_SET_GROUP_ERROR](state, { errors, group_id }) {
    state.isUpdatingIntegration = false;
    state.errorsSettingGroup = errors;

    // check for already existing group id
    const groupError = get(errors, "[0].message");

    if (groupError == "The group id is assigned to an existing integration.") {
      state.integrationGroups = state.integrationGroups.map((group) => {
        if (group.id == group_id) {
          return Object.assign(group, { unavailable: true });
        } else {
          return group;
        }
      });
    }
  },
  [types.REQUEST_QUERY_INTEGRATIONS](state) {
    state.isQueryingIntegrations = true;
  },
  [types.RECEIVE_QUERY_INTEGRATIONS_RESPONSE](state, { integrations, errors }) {
    state.isQueryingIntegrations = false;
    state.errorsQueryingIntegrations = errors;
    integrations = integrations.filter((int) => !!int); // Items that error will be null here
    state.integrations = integrations;
  },
  [types.REQUEST_CREATE_INTEGRATION](state) {
    state.isCreatingIntegration = true;
  },
  [types.RECEIVE_CREATE_INTEGRATION_SUCCESS](state, integration) {
    state.isCreatingIntegration = false;
    state.integration = integration;
    state.errorsCreatingIntegration = [];
  },
  [types.RECEIVE_CREATE_INTEGRATION_ERROR](state, errors) {
    state.isCreatingIntegration = false;
    state.integration = null;
    state.errorsCreatingIntegration = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_CREDENTIALS](state) {
    state.isUpdatingIntegrationCredentials = true;
    state.updatingIntegrationCredentialsSuccess = false;
    state.errorsUpdatingIntegrationCredentials = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_SUCCESS](state) {
    state.isUpdatingIntegrationCredentials = false;
    state.updatingIntegrationCredentialsSuccess = true;
    state.errorsUpdatingIntegrationCredentials = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_CREDENTIALS_ERROR](state, errors) {
    state.isUpdatingIntegrationCredentials = false;
    state.updatingIntegrationCredentialsSuccess = false;
    state.errorsUpdatingIntegrationCredentials = errors;
  },
  [types.REQUEST_UPDATE_DD_NOTIFICATION_CHANNEL](state) {
    state.isUpdatingIntegration = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_SUCCESS](state, integration) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsUpdatingDatadogNotificationChannel = [];
  },
  [types.RECEIVE_UPDATE_DD_NOTIFICATION_CHANNEL_ERROR](state, errors) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingDatadogNotificationChannel = errors;
  },
  [types.REQUEST_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS](state) {
    state.isUpdatingIntegration = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_SUCCESS](
    state,
    integration,
  ) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsUpdatingDatadogSetWebhooksOnMonitors = [];
  },
  [types.RECEIVE_UPDATE_DD_SET_WEBHOOKS_ON_MONITORS_ERROR](state, errors) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingDatadogSetWebhooksOnMonitors = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_BASE_URL](state) {
    state.isUpdatingIntegration = true;
    state.updateIntegrationBaseUrlSuccess = false;
    state.errorsUpdatingIntegrationBaseUrl = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_BASE_URL_SUCCESS](state, integration) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.updateIntegrationBaseUrlSuccess = true;
    state.errorsUpdatingIntegrationBaseUrl = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_BASE_URL_ERROR](state, errors) {
    state.isUpdatingIntegration = false;
    state.updateIntegrationBaseUrlSuccess = false;
    state.errorsUpdatingIntegrationBaseUrl = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY](state) {
    state.isUpdatingIntegration = true;
  },
  [types.RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_SUCCESS](
    state,
    integration,
  ) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsUpdatingAllowedRepoVisibility = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_ALLOWED_REPO_VISIBILITY_ERROR](
    state,
    errors,
  ) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingAllowedRepoVisibility = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED](state) {
    state.isUpdatingIntegration = true;
    state.isUpdatingIntegrationServiceDiscoveryEnabled = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_SUCCESS](
    state,
    { integration },
  ) {
    state.isUpdatingIntegration = false;
    state.isUpdatingIntegrationServiceDiscoveryEnabled = false;
    state.integration = integration;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_ENABLED_ERROR](
    state,
    errors,
  ) {
    state.isUpdatingIntegration = false;
    state.isUpdatingIntegrationServiceDiscoveryEnabled = false;
    state.errorsUpdatingIntegration = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED](state) {
    state.isUpdatingIntegration = true;
    state.isUpdatingIntegrationSBOMGenerationEnabled = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_SUCCESS](
    state,
    { integration },
  ) {
    state.isUpdatingIntegration = false;
    state.isUpdatingIntegrationSBOMGenerationEnabled = false;
    state.integration = integration;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_SBOM_GENERATION_ENABLED_ERROR](
    state,
    errors,
  ) {
    state.isUpdatingIntegration = false;
    state.isUpdatingIntegrationSBOMGenerationEnabled = false;
    state.errorsUpdatingIntegration = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_TEAM_SYNC](state) {
    state.isUpdatingIntegration = true;
  },
  [types.RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_SUCCESS](state, integration) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsUpdatingTeamSync = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_TEAM_SYNC_ERRORS](state, errors) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingTeamSync = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_WARNING](state) {
    state.isUpdatingIntegration = true;
  },
  [types.RECEIVE_UPDATE_INTEGRATION_WARNING_SUCCESS](state, integration) {
    state.isUpdatingIntegration = false;
    state.integration.warnings = integration?.warnings;
    state.errorsUpdatingWarning = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_WARNING_ERRORS](state, errors) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingWarning = errors;
  },
  [types.REQUEST_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES](state) {
    state.isUpdatingIntegration = true;
    state.isUpdatingIntegrationServiceDiscoveryIgnoreRules = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_SUCCESS](
    state,
    { integration },
  ) {
    state.isUpdatingIntegration = false;
    state.isUpdatingIntegrationServiceDiscoveryIgnoreRules = false;
    state.integration = integration;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_INTEGRATION_SERVICE_DISCOVERY_IGNORE_RULES_ERROR](
    state,
    errors,
  ) {
    state.isUpdatingIntegration = false;
    state.isUpdatingIntegrationServiceDiscoveryIgnoreRules = false;
    state.errorsUpdatingIntegration = errors;
  },
  [types.REQUEST_FETCH_GIT_FORGE_INTEGRATIONS](state) {
    state.isFetchingGitIntegration = true;
    state.errorsFetchingGitIntegration = [];
  },
  [types.RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_SUCCESS](state, gitIntegrations) {
    state.isFetchingGitIntegration = false;
    state.errorsFetchingGitIntegration = [];
    state.gitIntegrations = gitIntegrations;
  },
  [types.RECEIVE_FETCH_GIT_FORGE_INTEGRATIONS_ERRORS](state, errors) {
    state.isFetchingGitIntegration = false;
    state.errorsFetchingGitIntegration = errors;
  },
  [types.REQUEST_FETCH_AVAILABLE_INTEGRATIONS](state) {
    state.isFetchingAvailableIntegrations = true;
    state.errorsFetchingAvailableIntegrations = [];
  },
  [types.RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_SUCCESS](
    state,
    { availableIntegrations, integrationCategories },
  ) {
    state.isFetchingAvailableIntegrations = false;
    state.errorsFetchingAvailableIntegrations = [];
    state.availableIntegrations = availableIntegrations;
    state.integrationCategories = integrationCategories;
  },
  [types.RECEIVE_FETCH_AVAILABLE_INTEGRATIONS_ERRORS](state, errors) {
    state.isFetchingAvailableIntegration = false;
    state.errorsFetchingAvailableIntegration = errors;
  },
  [types.REQUEST_DELETE_INTEGRATION](state) {
    state.isDeletingIntegration = true;
    state.errorsDeletingIntegration = [];
  },
  [types.RECEIVE_DELETE_INTEGRATION_SUCCESS](state) {
    state.isDeletingIntegration = false;
    state.errorsDeletingIntegration = [];
    state.successDeletingIntegration = true;
  },
  [types.RECEIVE_DELETE_INTEGRATION_ERRORS](state, errors) {
    state.isDeletingIntegration = false;
    state.errorsDeletingIntegration = errors;
    state.successDeletingIntegration = false;
  },
  [types.REQUEST_VALIDATE_INTEGRATION_CREDENTIALS](state) {
    state.isValidatingIntegrationCredentials = true;
    state.integrationValidationCredentialsErrors = [];
  },
  [types.RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_SUCCESS](
    state,
    { errors, organizations, user, tokenOwnerChange },
  ) {
    state.isValidatingIntegrationCredentials = false;
    state.integrationValidationCredentialsErrors = errors;
    state.organizationsFromValidatedCredentials = organizations;
    state.userFromValidatedCredentials = user;
    state.tokenOwnerChange = tokenOwnerChange;
  },
  [types.RECEIVE_VALIDATE_INTEGRATION_CREDENTIALS_ERRORS](state, errors) {
    state.isValidatingIntegrationCredentials = false;
    state.integrationValidationCredentialsErrors = errors;
  },

  [types.REQUEST_GENERIC_INTEGRATIONS](state) {
    state.isRequestingGenericIntegrations = true;
  },
  [types.RECEIVE_GENERIC_INTEGRATIONS_SUCCESS](state, integrations) {
    state.isRequestingGenericIntegrations = false;
    state.genericIntegrations = integrations;
  },
  [types.RECEIVE_GENERIC_INTEGRATIONS_ERRORS](state, errors) {
    state.isRequestingGenericIntegrations = false;
    state.errorsRequestingGenericIntegrations = errors;
  },
  [types.REQUEST_INTEGRATION](state) {
    state.isFetchingIntegration = true;
  },
  [types.RECEIVE_INTEGRATION_SUCCESS](state, { integration }) {
    state.isFetchingIntegration = false;
    state.integration = integration;
  },
  [types.RECEIVE_INTEGRATION_ERRORS](state, errors) {
    state.isFetchingIntegration = false;
    state.errorsFetchingIntegration = errors;
  },
  [types.REQUEST_NEW_RELIC_ACCOUNTS](state) {
    state.isFetchingNewRelicAccounts = true;
    state.errorsFetchingNewRelicAccounts = [];
    state.newRelicAccounts = [];
  },
  [types.RECEIVE_NEW_RELIC_ACCOUNTS_ERRORS](state, errors) {
    state.isFetchingNewRelicAccounts = false;
    state.errorsFetchingNewRelicAccounts = errors;
  },
  [types.RECEIVE_NEW_RELIC_ACCOUNTS_SUCCESS](state, accounts) {
    state.isFetchingNewRelicAccounts = false;
    state.errorsFetchingNewRelicAccounts = [];
    state.newRelicAccounts = accounts;
  },
  [types.REQUEST_CREATE_SAMPLE_GIT_INTEGRATION](state) {
    state.isCreatingSampleGitIntegration = true;
    state.errorsCreatingSampleGitIntegration = [];
    state.sampleGitIntegration = null;
  },
  [types.RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_ERRORS](state, errors) {
    state.isCreatingSampleGitIntegration = false;
    state.errorsCreatingSampleGitIntegration = errors;
  },
  [types.RECEIVE_CREATE_SAMPLE_GIT_INTEGRATION_SUCCESS](state, integration) {
    state.isCreatingSampleGitIntegration = false;
    state.errorsCreatingSampleGitIntegration = [];
    state.sampleGitIntegration = integration;
  },
  [types.REQUEST_SYNC_INTEGRATION](state) {
    state.isSyncingIntegration = true;
    state.errorsSyncingIntegration = [];
  },
  [types.RECEIVE_SYNC_INTEGRATION_SUCCESS](state) {
    state.isSyncingIntegration = false;
    state.recentlySyncedIntegration = true;
    state.errorsSyncingIntegration = [];
  },
  [types.RECEIVE_SYNC_INTEGRATION_ERROR](state, { errors }) {
    state.isSyncingIntegration = false;
    state.errorsSyncingIntegration = errors;
  },
  [types.REQUEST_UPDATE_OCTOPUS](state) {
    state.isUpdatingIntegration = true;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_OCTOPUS_SUCCESS](state, { integration }) {
    state.isUpdatingIntegration = false;
    state.integration = integration;
    state.errorsUpdatingIntegration = [];
  },
  [types.RECEIVE_UPDATE_OCTOPUS_ERRORS](state, errors) {
    state.isUpdatingIntegration = false;
    state.errorsUpdatingIntegration = errors;
  },
};
